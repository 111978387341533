var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "flex justify-between items-center mb-6"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Exercise")]), _c('Button', {
    attrs: {
      "buttonText": "Add New Exercise"
    },
    on: {
      "action": _vm.addData
    }
  })], 1), _c('div', {
    staticClass: "bg-white p-4 shadow-small rounded-lg card"
  }, [_c('div', {
    staticClass: "flex gap-6 items-end mb-6"
  }, [_c('div', {
    staticClass: "w-80"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Search",
      "enterKeyAction": _vm.onSearch,
      "borderEnabled": "",
      "placeholder": "Search by Exercise Name..."
    },
    model: {
      value: _vm.options['nama'],
      callback: function callback($$v) {
        _vm.$set(_vm.options, 'nama', $$v);
      },
      expression: "options['nama']"
    }
  })], 1), _c('div', {
    staticClass: "w-60"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Filter By Type")]), _c('Dropdown', {
    attrs: {
      "options": _vm.list_exercise_type,
      "optionLabel": "name",
      "default": _vm.selectedExerciseType,
      "placeholder": "Select Exercise Type"
    },
    model: {
      value: _vm.selectedExerciseType,
      callback: function callback($$v) {
        _vm.selectedExerciseType = $$v;
      },
      expression: "selectedExerciseType"
    }
  })], 1), _c('div', {
    staticClass: "w-60"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Filter by Class")]), _c('Dropdown', {
    attrs: {
      "options": _vm.coreClassOptions,
      "optionLabel": "nama",
      "default": _vm.coreClassFilterSelected,
      "enableSearch": "",
      "isLoadData": !_vm.isCoreClassDataReady,
      "placeholder": "Select Class"
    },
    on: {
      "search": _vm.getCoreClassOptions
    },
    model: {
      value: _vm.coreClassFilterSelected,
      callback: function callback($$v) {
        _vm.coreClassFilterSelected = $$v;
      },
      expression: "coreClassFilterSelected"
    }
  })], 1), _c('div', [_c('Button', {
    attrs: {
      "buttonText": "Apply",
      "additionalClass": "py-3"
    },
    on: {
      "action": _vm.onSearch
    }
  })], 1)]), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "pagination-options": {
        enabled: true,
        mode: 'pages',
        perPage: _vm.perPage,
        position: 'bottom',
        perPageDropdown: [5, 10, 25, 50],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        // for 'pages' mode
        allLabel: 'All'
      },
      "totalRows": _vm.totalRecords,
      "rows": _vm.data,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false
      },
      "row-style-class": _vm.rowStyleClassFn
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'btn' ? _c('div', {
          staticClass: "flex flex-col gap-2"
        }, [_c('div', {
          staticClass: "flex justify-start items-center"
        }, [_c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "tertiary",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Pencil');
            },
            "forIcon": {
              height: '24',
              width: '24',
              color: 'yellow'
            },
            "tooltip": {
              show: true,
              text: 'Edit'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.editMaster(props.row.id);
            }
          }
        }), _c('Button', {
          staticClass: "mr-2",
          attrs: {
            "type": "secondary",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Duplicate');
            },
            "forIcon": {
              height: '24',
              width: '24',
              color: 'yellow'
            },
            "tooltip": {
              show: true,
              text: 'Duplicate'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.duplicateMaster(props.row.id, props.row.name);
            }
          }
        }), _c('Button', {
          attrs: {
            "type": "delete",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Trash');
            },
            "tooltip": {
              show: true,
              text: 'Delete'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.deleteMaster(props.row.id, props.row.name);
            }
          }
        })], 1)]) : props.column.field === 'created_date' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : props.column.field === 'name' ? _c('div', [_c('div', {
          staticClass: "max-w-sm"
        }, [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])]) : props.column.field === 'updated_date' ? _c('div', [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).date) + " ")]), _c('span', {
          staticStyle: {
            "display": "block",
            "font-size": "14px",
            "color": "#333"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(props.formattedRow[props.column.field]).time) + " ")])]) : props.column.field === 'kelas_nama' ? _c('div', {
          staticClass: "max-w-sm"
        }, [_c('span', {
          staticStyle: {
            "display": "block",
            "white-space": "nowrap"
          }
        }, [_vm._v(_vm._s(props.formattedRow[props.column.field]))])]) : props.column.field === 'exam_url' ? _c('span', [_c('a', {
          attrs: {
            "href": props.row.exam_url,
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(props.row.exam_url))])]) : props.column.field === 'exam_url_array' ? _c('span', _vm._l(props.row.exam_url_array, function (item_exam, item_exam_index) {
          return _c('a', {
            key: 'exam' + item_exam_index,
            attrs: {
              "href": item_exam,
              "target": "_blank"
            }
          }, [_vm._v(" " + _vm._s(item_exam))]);
        }), 0) : props.column.field === 'exam_sheet' ? _c('span', [_c('a', {
          attrs: {
            "href": 'https://docs.google.com/spreadsheets/d/' + props.row.exam_sheet,
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(props.row.exam_sheet))])]) : props.column.field === 'exercise_type_name' ? _c('span', [_vm._v(" " + _vm._s(_vm.exerciseNameMapping[props.row.exercise_type_name]) + " ")]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }