<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else>
      <div class="flex justify-between items-center mb-6">
        <h1 class="text-3xl font-bold">Exercise</h1>
        <Button buttonText="Add New Exercise" @action="addData" />
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="flex gap-6 items-end mb-6">
          <div class="w-80">
            <TextField type="text" label="Search" :enterKeyAction="onSearch" v-model="options['nama']" borderEnabled placeholder="Search by Exercise Name..." />
          </div>
          <div class="w-60">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Filter By Type</label>
            <Dropdown :options="list_exercise_type" optionLabel="name" v-model="selectedExerciseType" :default="selectedExerciseType" placeholder="Select Exercise Type" />
          </div>
          <div class="w-60">
            <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Filter by Class</label>
            <Dropdown
              :options="coreClassOptions"
              optionLabel="nama"
              v-model="coreClassFilterSelected"
              :default="coreClassFilterSelected"
              enableSearch
              :isLoadData="!isCoreClassDataReady"
              @search="getCoreClassOptions"
              placeholder="Select Class"
            />
          </div>
          <div>
            <Button buttonText="Apply" @action="onSearch" additionalClass="py-3" />
          </div>
        </div>
        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: perPage,
            position: 'bottom',
            perPageDropdown: [5, 10, 25, 50],
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All'
          }"
          :totalRows="totalRecords"
          :rows="data"
          :columns="columns"
          :sort-options="{
            enabled: false
          }"
          @on-page-change="onPageChange"
          @on-per-page-change="onPageChange"
          :row-style-class="rowStyleClassFn"
        >
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'btn'" class="flex flex-col gap-2">
              <div class="flex justify-start items-center">
                <Button
                  class="mr-2"
                  type="tertiary"
                  size="icon"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil')"
                  :forIcon="{ height: '24', width: '24', color: 'yellow' }"
                  :tooltip="{ show: true, text: 'Edit' }"
                  @action="editMaster(props.row.id)"
                />
                <Button
                  class="mr-2"
                  type="secondary"
                  size="icon"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Duplicate')"
                  :forIcon="{ height: '24', width: '24', color: 'yellow' }"
                  :tooltip="{ show: true, text: 'Duplicate' }"
                  @action="duplicateMaster(props.row.id, props.row.name)"
                />
                <Button
                  type="delete"
                  size="icon"
                  :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                  :tooltip="{ show: true, text: 'Delete' }"
                  @action="deleteMaster(props.row.id, props.row.name)"
                />
              </div>
            </div>
            <div v-else-if="props.column.field === 'created_date'">
              <span style="display: block; white-space: nowrap">
                {{ formatDate(props.formattedRow[props.column.field]).date }}
              </span>
              <span style="display: block; font-size: 14px; color: #333">
                {{ formatDate(props.formattedRow[props.column.field]).time }}
              </span>
            </div>
            <div v-else-if="props.column.field === 'name'">
              <div class="max-w-sm">
                {{ props.formattedRow[props.column.field] }}
              </div>
            </div>
            <div v-else-if="props.column.field === 'updated_date'">
              <span style="display: block; white-space: nowrap">
                {{ formatDate(props.formattedRow[props.column.field]).date }}
              </span>
              <span style="display: block; font-size: 14px; color: #333">
                {{ formatDate(props.formattedRow[props.column.field]).time }}
              </span>
            </div>
            <div v-else-if="props.column.field === 'kelas_nama'" class="max-w-sm">
              <span style="display: block; white-space: nowrap">{{ props.formattedRow[props.column.field] }}</span>
            </div>
            <span v-else-if="props.column.field === 'exam_url'">
              <a :href="props.row.exam_url" target="_blank"> {{ props.row.exam_url }}</a>
            </span>
            <span v-else-if="props.column.field === 'exam_url_array'">
              <a v-for="(item_exam, item_exam_index) in props.row.exam_url_array" :href="item_exam" target="_blank" :key="'exam' + item_exam_index"> {{ item_exam }}</a>
            </span>
            <span v-else-if="props.column.field === 'exam_sheet'">
              <a :href="'https://docs.google.com/spreadsheets/d/' + props.row.exam_sheet" target="_blank"> {{ props.row.exam_sheet }}</a>
            </span>
            <span v-else-if="props.column.field === 'exercise_type_name'">
              {{ exerciseNameMapping[props.row.exercise_type_name] }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

const EXERCISE_NAME_MAPPING = {
  EXAM: 'Post-Test',
  PRETEST: 'Pre-Test',
  SURVEY: 'Instructor Feedback',
  QUIZ: 'Quiz',
  ASSIGNMENT: 'Assignment'
}
export default {
  name: 'Exercise',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown')
  },
  mounted: function () {
    this.selectedExerciseType = this.list_exercise_type[0]
    this.initData()
  },
  data: function () {
    return {
      searchTerm: null,
      options: {
        limit: 10,
        page: 0,
        sortBy: 'createdDate',
        direction: 'DESC',
        nama: '',
        questionType: '',
        kelas_id: ''
      },
      selectedExerciseType: null,
      //columns: [],
      perPage: 10,
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: '',
      coreClassFilterSelected: null,
      coreClassOptions: [],
      isCoreClassDataReady: true,
      list_exercise_type: [
        { id: 0, name: 'All', key: '' },
        { id: 1, name: 'Pretest', key: 'PRETEST' },
        { id: 2, name: 'Quiz', key: 'QUIZ' },
        { id: 3, name: 'PostTest', key: 'EXAM' },
        { id: 4, name: 'Assignment', key: 'ASSIGNMENT' },
        { id: 5, name: 'Instructor Feedback', key: 'SURVEY' }
      ]
    }
  },
  methods: {
    ...mapActions('kelas', ['GET_LIST_KELAS']),
    formatDate(date) {
      if (date) {
        return {
          date: moment(date).local().format('DD MMM YYYY'),
          time: moment(date).local().format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    },
    initData() {
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      let paramsTemp = queryString.stringify({
        ...{
          nama: null
        },
        ...this.options
      })
      const customerId = this.clientId
      this.$store
        .dispatch('exercise/GET_LIST_EXERCISE', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId: customerId
        })
        .then(() => {
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
        })
    },
    unlock() {
      if (this.unlockPassword === this.$store.getters['customer/customer'].password) {
        this.locked = false
      } else {
        alert('Password infalid')
      }
    },
    preview(masterId) {
      this.$router.push('/exercise/' + masterId)
    },
    onPageChange(params) {
      this.options.limit = params.currentPerPage
      this.options.page = params.currentPage - 1
      let paramsTemp = queryString.stringify({
        ...{
          limit: params.currentPerPage,
          page: params.currentPage - 1,
          questionType: this.selectedExerciseType || '',
          kelas_id: this.coreClassFilterSelected?.id || '',
          sort: ''
        },
        ...this.options
      })
      const customerId = this.clientId
      this.$store.dispatch('exercise/GET_LIST_EXERCISE', {
        masterType: this.$route.meta.name,
        params: paramsTemp,
        customerId: customerId
      })
    },

    onSearch(event) {
      this.options.page = 0
      this.options.questionType = this.selectedExerciseType?.key || ''
      this.options.kelas_id = this.coreClassFilterSelected?.id || ''
      let paramsTemp = queryString.stringify({
        ...this.options
      })
      const customerId = this.clientId
      this.$store.dispatch('exercise/GET_LIST_EXERCISE', {
        masterType: this.$route.meta.name,
        params: paramsTemp,
        customerId: customerId
      })
    },
    editMaster(masterId) {
      this.$router.push('/exercise/edit/' + masterId)
    },
    deleteMaster(masterId, name) {
      let paramsTemp = queryString.stringify({
        ...this.options
      })

      this.$dialog
        .confirm(`Are you sure you want to delete "${name}"?`, {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          const customerId = this.clientId
          this.$store
            .dispatch('exercise/DELETE_EXERCISE', {
              masterType: this.$route.meta.name,
              masterId: masterId,
              customerId: customerId
            })
            .then((resp) => {
              if (resp.data.status === '200 OK') {
                // this.$swal(resp.data.message)
                const customerId = this.clientId
                this.$store.dispatch('exercise/GET_LIST_EXERCISE', {
                  masterType: this.$route.meta.name,
                  params: paramsTemp,
                  customerId: customerId
                })
              }
            })
        })
        .catch(function () {})
    },
    duplicateMaster(masterId, name) {
      let paramsTemp = queryString.stringify({
        ...this.options
      })

      this.$dialog
        .confirm(`Are you sure you want to duplicate "${name}" exercise?`, {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          const customerId = this.clientId
          this.$store
            .dispatch('exercise/DUPLICATE_EXERCISE', {
              masterType: this.$route.meta.name,
              masterId: masterId,
              payload: { name: 'Duplicate of ' + name },
              customerId: customerId
            })
            .then((resp) => {
              if (resp.data.status === '200 OK') {
                // this.$swal(resp.data.message)
                const customerId = this.clientId
                this.$store.dispatch('exercise/GET_LIST_EXERCISE', {
                  masterType: this.$route.meta.name,
                  params: paramsTemp,
                  customerId: customerId
                })
              }
            })
        })
        .catch(function () {})
    },

    addData() {
      this.$router.push('/' + this.$route.meta.name + '/Add')
    },
    openDisplay() {
      this.$router.push('/display/' + this.$store.getters['customer/customer'].token_trx)
    },
    resetDisplay() {
      this.$store.dispatch('customer/RESET_TOKEN_TRX', {}).then((resp) => {
        if (resp.data.status === '200 OK') {
          // this.$swal(resp.data.status)
        }
      })
    },
    rowStyleClassFn(row) {
      let result = 'default'
      // if (row.id % 2 === 1 ){
      //     result='green'
      // } else if (row.id === 0 ){
      //     result='red'
      // }
      return result
    },
    getCoreClassOptions(name) {
      this.isCoreClassDataReady = false
      const params = queryString.stringify({
        name,
        limit: 10
      })
      this.GET_LIST_KELAS({ params, customerId: this.clientId }).then((res) => {
        if (res?.data?.code === 200) {
          this.coreClassOptions = res?.data?.data
        }
        this.isCoreClassDataReady = true
      })
    }
  },
  computed: {
    exerciseNameMapping() {
      return EXERCISE_NAME_MAPPING
    },
    clientId() {
      return localStorage.getItem('client')
    },
    data: function () {
      return this.$store.getters['exercise/list_exercise'] ? this.$store.getters['exercise/list_exercise'] : []
    },
    totalRecords: function () {
      return this.$store.getters['exercise/paginate'].total ? this.$store.getters['exercise/paginate'].total : 0
    },
    columns: function () {
      let tempColumnsNew = [
        { field: 'id', label: 'Id' },
        { field: 'name', label: 'Name' },
        { field: 'exercise_type_name', label: 'Exercise Type' },
        { field: 'kelas_nama', label: 'Class' },
        { field: 'updated_date', label: 'Updated Date' },
        { field: 'created_date', label: 'Created Date' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]

      return tempColumnsNew
    },
    level_id: function () {
      return this.$store.getters['customer/customer'] ? this.$store.getters['customer/customer'].level_id : 0
    }
  },
  created: function () {
    // this.initData()
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    }
  }
}
</script>
<style scoped lang="scss">
.red {
  background: linear-gradient(#f4f5f8, #e2133f);
}
.green {
  background: linear-gradient(#f4f5f8, #57c137);
}
.default {
  background: linear-gradient(#f4f5f8, #f1f3f6);
}
.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
